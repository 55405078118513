const DEFAULT_IMAGE_SIZE = [224, 224]
const DEFAULT_UPSAMPLE = false

type ArrayKeys<T> = {
  [K in keyof T]: T[K] extends unknown[] ? K : never
}[keyof T]

class ClassificationRunArgs {
  static keys() {
    return ['imgSize', 'upsample']
  }

  static joinerMap(): Record<ArrayKeys<ClassificationRunArgs>, string> {
    return {
      imgSize: 'x',
    }
  }

  static displayKeyMap(): Record<Exclude<keyof ClassificationRunArgs, 'toJSON'>, string> {
    return {
      imgSize: 'Image Size',
      upsample: 'Oversample',
    }
  }

  public imgSize: number[] = $state(DEFAULT_IMAGE_SIZE)
  public upsample: boolean = $state(DEFAULT_UPSAMPLE)

  toJSON() {
    return {
      imgSize: this.imgSize,
      upsample: this.upsample,
    }
  }
}

class ObjectDetectionRunArgs extends ClassificationRunArgs {
  static keys() {
    return [
      ...super.keys(),
      'minAbsBboxSize',
      'minAbsBboxArea',
      'minRelBboxSize',
      'minRelBboxArea',
      'cropRatio',
      'addMaskChannel',
    ]
  }

  static displayKeyMap(): Record<Exclude<keyof ObjectDetectionRunArgs, 'toJSON'>, string> {
    return {
      ...super.displayKeyMap(),
      minAbsBboxSize: 'Minimum absolute bbox size',
      minAbsBboxArea: 'Minimum absolute bbox area',
      minRelBboxSize: 'Minimum relative bbox size',
      minRelBboxArea: 'Minimum relative bbox area',
      cropRatio: 'Crop ratio',
      addMaskChannel: 'Add mask channel',
    }
  }

  public minAbsBboxSize: number = $state(0)
  public minAbsBboxArea: number = $state(0)
  public minRelBboxSize: number = $state(0.0)
  public minRelBboxArea: number = $state(0.0)
  public cropRatio: number | null = $state(1.0)
  public addMaskChannel: boolean = $state(false)

  toJSON() {
    return {
      ...super.toJSON(),
      minAbsBboxSize: this.minAbsBboxSize,
      minAbsBboxArea: this.minAbsBboxArea,
      minRelBboxSize: this.minRelBboxSize,
      minRelBboxArea: this.minRelBboxArea,
      cropRatio: this.cropRatio,
      addMaskChannel: this.addMaskChannel,
    }
  }
}

export { ClassificationRunArgs, ObjectDetectionRunArgs }
